.react-datepicker__input-container input {
    background-color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    height: 30px;
    border: none;
    border-bottom: 1px solid grey;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
}

.react-datepicker__input-container input:focus {
    border: 0;
    border-bottom: 2px solid #3f51b5;
    outline: 0;
}
